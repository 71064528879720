import { render, staticRenderFns } from "./index.vue?vue&type=template&id=4fe2451e&scoped=true"
import script from "./index.vue?vue&type=script&lang=ts"
export * from "./index.vue?vue&type=script&lang=ts"
import style0 from "./index.vue?vue&type=style&index=0&id=4fe2451e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fe2451e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RPHSmartBanner: require('/usr/src/app/src/components/v2/organisms/RPHSmartBanner/RPHSmartBanner.vue').default,RPHBurgerMenu: require('/usr/src/app/src/components/v2/organisms/RPHBurgerMenu/index.vue').default,RPHHeaderWhatsApp: require('/usr/src/app/src/components/v2/molecules/RPHHeaderWhatsApp/index.vue').default,RPHHeaderBurger: require('/usr/src/app/src/components/v2/atoms/RPHHeaderBurger/index.vue').default,RPHHeaderLocation: require('/usr/src/app/src/components/v2/organisms/RPHHeaderLocation/index.vue').default,RPHHeaderBonus: require('/usr/src/app/src/components/v2/organisms/RPHHeaderBonus/index.vue').default,RPHSearchBar: require('/usr/src/app/src/components/v2/organisms/RPHSearchBar/index.vue').default})
